// Bootstrap overrides
$primary: #fd7e14;
$secondary: #dadada;

// Remove xxl breakpoint (1400px/1320px)
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  // lg: 992px,
  xl: 1200px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  // lg: 960px,
  xl: 1140px
);

// Bootstrap and its default variables
@import "node_modules/bootstrap/scss/bootstrap";

// Ag Grid
@import "../node_modules/ag-grid-community/src/styles/ag-grid.scss";
@import "../node_modules/ag-grid-community/src/styles/ag-theme-alpine-dark/sass/ag-theme-alpine-dark-mixin";

// Ag Grid Dark theme override
.ag-theme-amplified-dark {
    @include ag-theme-alpine-dark((
        alpine-active-color: $primary,
        range-selection-border-color: $primary,
        border-color: #181818
    ));
}

.ag-root-wrapper {
  border: 0px;
}

// Application overrides
body {
    margin: 0;
    font-family: 'Roboto', 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #212529;
    color: whitesmoke;
}
